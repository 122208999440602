<template>
  <div class="team-container is-clickable has-text-centered" @click="$emit('click', team)">
    <div class="team-logo">
      <img :src="team.logo" :alt="team.team_name" />
    </div>
    <div class="team-name">
      {{ team.team_name }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'TeamItem',
  props: {
    team: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.team-container {
  height: 89px;
  width: 115px;
  margin: 9px;
  border-radius: 6px;
  border: solid 1px #dadada;
  &:hover {
    border: solid 1px #7ed321;
  }
}

.team-logo {
  height: 59px;
  width: 59px;
  margin: 11px auto 0;
  & > img {
    object-fit: contain;
    ms-filter: gray;
    // Google Chrome, Safari 6+ & Opera 15+
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
  }
  & > img:hover {
    -webkit-filter: grayscale(0);
    filter: none;
  }
}

.team-name {
  margin-top: 3px;
  text-transform: uppercase;
  font-size: 7px;
}
</style>
